import { Options, Vue } from "vue-class-component";
import { IEmployeeNewEdit } from "@/types/Employee";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { IGender } from "@/types/Employee";
import { ISetting } from "@/types/Setting";
import DropdownComponent from "@/components/DropdownComponent.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import AuthStore from "@/store/auth-store";
import draggable from "vuedraggable";
import { formatDate_h, notify, useFormatDate } from "@/services/helpers";
import ProfileImage from "@/views/employees/ProfileImage.vue";

@Options({
  name: "EditEmployee",
  components: {
    ProfileImage,
    Breadcrumb,
    Dropdown: DropdownComponent,
    ConfirmationModal,
    draggable,
  },
  data: function () {
    return {};
  },
  props: {},
})
export default class EditEmployee extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();

  gender: IGender[] = [
    { id: 0, value: "Male" },
    { id: 1, value: "Female" },
    { id: 2, value: "Prefer not to say" },
  ];
  async goToRoute(route: string) {
    await this.$router.push(route);
  }

  rolesEnum = {
    admin: "Admin",
    manager: "Manager",
    orgAdmin: "Org Admin",
  };

  authId = AuthStore.getTokenData().EmployeeId;
  authRole = AuthStore.getTokenData().Roles;
  isAdmin =
    AuthStore.getTokenData().Roles == this.rolesEnum.admin ||
    AuthStore.getTokenData().Roles == this.rolesEnum.orgAdmin;
  canEdit = false;

  employeeId = 0;

  formatDate = formatDate_h;

  useFormatDate = useFormatDate;

  $refs!: {
    confirmDialogue: HTMLFormElement;
  };

  crumbs: any = ["Dashboard", "Employees", "Edit Employee"];

  loading = false;

  editEmployee: IEmployeeNewEdit = {
    id: 0,
    firstName: "",
    lastName: "",
    gender: "",
    homeNumber: "",
    maritalStatus: "",
    medicalConditions: "",
    mobileNumber: "",
    smsNotification: false,
    officeNumber: "",
    personalEmail: "",
    workEmail: "",
    department: "",
    departmentId: 0,
    workLocationId: 0,
    jobTitle: "",
    isActive: false,
    departmentName: "",
    manager: "",
    managerId: 0,
    addressId: 0,
    leavesQuota: 0,
    carryOverDays: 0,
    dob: "",
    jobCommencementDate: "",
    role: "",
  };

  async employeeBookLeave(empId: number, name: string) {
    this.$router.push({
      name: "Leaves",
      params: { id: btoa(empId.toString()), name: btoa(name) },
    });
  }

  canInviteOrEdit(loggedInRole: string, inviteeRole: string) {
    if (
      loggedInRole == this.rolesEnum.manager &&
      (inviteeRole == this.rolesEnum.orgAdmin ||
        inviteeRole == this.rolesEnum.admin)
    ) {
      return false;
    }
    return true;
  }

  canDeactivate(role: string, id: number) {
    if (
      this.authRole == this.rolesEnum.admin ||
      (this.authRole == this.rolesEnum.orgAdmin &&
        role != this.rolesEnum.orgAdmin &&
        id != this.authId)
    ) {
      return true;
    }
    return false;
  }

  async inActiveEmployee(id: number) {
    this.employeeId = id;
    const modal = await this.$refs.confirmDialogue.show({
      title: "Deactivate Employee?",
      message: "Are you sure you want to deactivate this employee?",
      okButton: "Deactivate",
      cancelButton: "Cancel",
      icon: "warning",
      type: "confirmation",
    });
    // If you throw an error, the method will terminate here unless you surround it wil try/catch
    if (modal) {
      this.workForceService
        .delete(`/employee/${this.employeeId}`)
        .then((res: any) => {
          if (!res.isError) {
            this.initialize();
            notify("Employee deactivated successfully.", "Success", "success");
          } else {
            notify(res.errors, "Error", "danger");
          }
        });
      this.employeeId = 0;
    } else {
      this.employeeId = 0;
      return false;
    }
  }

  async reActiveEmployee(id: number) {
    this.employeeId = id;
    const modal = await this.$refs.confirmDialogue.show({
      title: "Reactivate Employee?",
      message: "Are you sure you want to reactivate this employee?",
      okButton: "Reactivate",
      cancelButton: "Cancel",
      icon: "warning",
      type: "confirmation",
    });
    // If you throw an error, the method will terminate here unless you surround it wil try/catch
    if (modal) {
      this.workForceService
        .postOrPut(
          `/employee/activate/${this.employeeId}`,
          null,
          this.employeeId.toString()
        )
        .then((res: any) => {
          if (!res.isError) {
            this.initialize();
            notify("Employee reactivated successfully.", "Success", "success");
          } else {
            notify(res.errors, "Error", "danger");
          }
        });
      this.employeeId = 0;
    } else {
      this.employeeId = 0;
      return false;
    }
  }

  async created() {
    this.canEdit =
      (AuthStore.getTokenData().Roles == this.rolesEnum.admin ||
        AuthStore.getTokenData().Roles == this.rolesEnum.manager ||
        AuthStore.getTokenData().Roles == this.rolesEnum.orgAdmin) &&
      this.$route.params.id !== this.authId;

    await this.initialize();
  }

  async initialize() {
    this.loading = true;

    // Load Employee
    const employeeId = this.$route.params.id;
    await this.workForceService
      .get<IEmployeeNewEdit>(`/employee/${employeeId}`, false)
      .then((response: any) => {
        if (
          response.content &&
          this.canInviteOrEdit(this.authRole, response.content.role)
        ) {
          this.editEmployee = response.content;
        } else {
          this.$router.back();
        }
        if (this.editEmployee.leavesQuota == 0) {
          this.workForceService
            .get<ISetting[]>(`/settings/LeavesQuota`, false)
            .then((response: any) => {
              this.editEmployee.leavesQuota = parseInt(response.content.value);
            });
        }
        if (this.editEmployee.carryOverDays == 0) {
          this.workForceService
            .get<ISetting[]>(`/settings/CarryOverDays`, false)
            .then((response: any) => {
              this.editEmployee.carryOverDays = parseInt(
                response.content.value
              );
            });
        }
      });

    this.loading = false;
  }
}
