<template>
  <!--  Breadcrumbs-->
  <Breadcrumb :crumbs="crumbs" />

  <!--  Content-->
  <div class="custom-container">
    <!--    Page Title-->
    <page-title v-if="editEmployee.firstName">
      <template #title>
        <span class="h4 text-primary"
          >Edit
          <span class="text-primary"
            >{{ editEmployee.firstName + " " + editEmployee.lastName }}'s</span
          >
          details</span
        >
      </template>
      <template #button>
        <div class="">
          <button
            class="btn btn-primary btn-sm"
            @click="
              employeeBookLeave(
                editEmployee.id,
                editEmployee.firstName + ' ' + editEmployee.lastName
              )
            "
          >
            <font-awesome-icon class="mr-1" icon="plus-circle" />
            Book Leave
          </button>
        </div>

        <!--        check if the data is loaded-->
        <span
          v-if="
            editEmployee.firstName &&
            canDeactivate(editEmployee.role, editEmployee.id)
          "
          class="ml-1"
        >
          <a
            v-if="editEmployee.isActive"
            class="btn btn-sm btn-danger"
            @click.prevent="inActiveEmployee(editEmployee.id)"
          >
            <font-awesome-icon class="mr-1" icon="ban" />
            Deactivate
          </a>
          <a
            v-if="!editEmployee.isActive"
            class="btn btn-sm btn-primary"
            @click.prevent="reActiveEmployee(editEmployee.id)"
          >
            <font-awesome-icon class="mr-1" icon="history" />
            Reactivate
          </a>
        </span>

        <span>
          <button
            class="btn btn-sm btn-secondary ml-1"
            @click="this.$router.back()"
          >
            <font-awesome-icon class="mr-1" icon="arrow-left" />
            Go Back
          </button>
        </span>
      </template>
    </page-title>

    <template v-else>
      <div class="card bg-light shadow-sm py-3">
        <loading-component flex></loading-component>
      </div>
    </template>

    <template v-if="!loading">
      <!--      profile Image-->
      <div class="row g-0 pb-2 align-items-center">
        <div class="col-2">
          <profile-image
            :is-editable="editEmployee.id == authId"
            :image="editEmployee.profileImage"
            :gender="editEmployee.gender"
            :employee-id="editEmployee.id"
          ></profile-image>
        </div>

        <div class="col-10">
          <card-row :avatar="false" margin="mb-0">
            <!--        Details-->
            <template class="py-4" v-if="editEmployee.firstName" #details>
              <!--            Row 1: Name and status-->
              <div class="d-md-flex align-items-center">
                <!--            Name-->

                <div class="font-weight-bold h5 my-0 mr-2">
                  {{ editEmployee.firstName }} {{ editEmployee.lastName }}
                </div>

                <div class="">
                  <span class="badge badge-pill badge-primary">
                    {{ editEmployee.jobTitle || "" }}
                  </span>
                </div>

                <div v-if="editEmployee.id == authId" class="ml-1">
                  <div :class="`badge badge-pill badge-dark`">It's Me</div>
                </div>
              </div>

              <!--            Row 3: Department-->
              <div class="d-md-flex align-items-center mt-1">
                <div class="mr-3">
                  <span class="mr-1">Department: </span>
                  <span class="badge badge-secondary">
                    {{ editEmployee.departmentName }}
                  </span>
                </div>
                <div class="">
                  <span class="mr-1">Role: </span>
                  <span class="badge badge-secondary">
                    {{ editEmployee.role }}
                  </span>
                </div>
              </div>

              <!--            Row 5: Email-->
              <div class="d-md-flex align-items-center mt-1">
                <div class="mr-3">
                  <span class="text-primary">
                    <font-awesome-icon class="mr-1" icon="at" />

                    {{ editEmployee.workEmail }}
                  </span>
                </div>
                <div class="">
                  <span class="text-muted">
                    <font-awesome-icon class="mr-1" icon="phone-alt" />
                    {{
                      editEmployee.officeNumber
                        ? editEmployee.officeNumber
                        : "No Data Available"
                    }}
                  </span>
                </div>
              </div>
            </template>
          </card-row>
        </div>
      </div>

      <div
        class="card mt-4 border-top-0 border-left-0 border-right-0 rounded-0"
      >
        <div class="card-body pb-0 px-0 overflow-auto">
          <ul class="nav">
            <li class="nav-item mr-3">
              <router-link
                class="tab-link font-weight-semibold h5"
                :to="`/admin/employee/edit/${$route.params.id}/details`"
                >Details</router-link
              >
            </li>
            <li class="nav-item mr-3">
              <router-link
                :to="`/admin/employee/edit/${$route.params.id}/goals`"
                class="tab-link font-weight-semibold h5"
                >Goals</router-link
              >
            </li>
            <li class="nav-item mr-3">
              <router-link
                :to="`/admin/employee/edit/${$route.params.id}/notes`"
                class="tab-link font-weight-semibold h5"
                >Notes</router-link
              >
            </li>
            <li class="nav-item mr-3">
              <router-link
                :to="`/admin/employee/edit/${$route.params.id}/emergency-contact`"
                class="tab-link font-weight-semibold h5"
                >Emergency Contacts</router-link
              >
            </li>
          </ul>
        </div>
      </div>

      <router-view></router-view>
    </template>

    <template v-else>
      <div class="card-body shadow-sm border rounded mt-3">
        <loading-component></loading-component>
      </div>
    </template>
  </div>

  <!--  Modals-->
  <ConfirmationModal ref="confirmDialogue"></ConfirmationModal>
</template>

<script src="./edit-employee.ts" />

<style scoped></style>
